<template>
  <div id="nav">
    <a class="link" href="https://www.cafeyn.co/fr/newsstand"> Cafeyn </a> | 
    <a class="link" href="https://www.cloud.lahouste.eu"> Cloud </a>
  </div>
</template>

<style scoped>
.link
{
   color: #42b983 !important;
   text-decoration: none; 
   background-color: none;
}
</style>